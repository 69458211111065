import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { getSrc } from "gatsby-plugin-image";

import SwiperCore, { Navigation, Thumbs, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/navigation/navigation.scss';

// Install Swiper modules
SwiperCore.use([Navigation, Thumbs, A11y]);

if (typeof window !== `undefined`) {
    gsap.registerPlugin(MorphSVGPlugin);
    MorphSVGPlugin.defaultType = 'rotational';
}



export default function ClientsCarousel() {
    const data = useStaticQuery(graphql`{
  projects: allMarkdownRemark(
    filter: {fileAbsolutePath: {glob: "**/content/projects/*.md"}}
  ) {
    edges {
      node {
        frontmatter {
          path
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 500, layout: FIXED)
            }
          }
          imageAlt
          logo {
            publicURL
          }
          summary
        }
      }
    }
  }
}
`)

    const projects = data.projects.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        rObj.image = getSrc(obj.node.frontmatter.image);
        rObj.imageAlt = obj.node.frontmatter.imageAlt;
        rObj.logo = obj.node.frontmatter.logo.publicURL;
        rObj.summary = obj.node.frontmatter.summary;
        return rObj
    });

    const onPortable = () => {
        return (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 1024);
    }

    const morphPath = {
        atRest: 'M50,0L100,0L50,100L0,100C24.377,72.416 46.218,42.943 50,0Z',
        right: 'M50,0L100,0L50,100L0,100C8.377,57.416 21.218,26.943 50,0Z',
        left: 'M50,0L100,0L50,100L0,100C39.377,83.416 58.218,48.943 50,0Z'
    };

    const slideChange = (swiper) => {
        if(onPortable()) return;

        const direction = (swiper.activeIndex > swiper.previousIndex) ? 'right' : 'left';
        let morphTimeline = gsap.timeline();
        morphTimeline
            .to(`#slide-${swiper.activeIndex}_path`, 0.25, {morphSVG: morphPath[direction], ease: 'power2.out', delay: 0.3})
            .to(`#slide-${swiper.activeIndex}_path`, 0.75, {morphSVG: morphPath['atRest'], ease: 'elastic.out(1, 0.5)'});
    };

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className="relative clients-carousel">
            <Swiper
                slidesPerView={1}
                navigation={{ nextEl: '.carousel-next', prevEl: '.carousel-prev' }}
                thumbs={{ swiper: thumbsSwiper }}
                autoHeight={true}
                onSlideChange={(swiper) => slideChange(swiper)}
                className="rounded-lg bg-gray-100 shadow-xl rounded-b-lg md:rounded-t-lg overflow-hidden border-2 border-gray-200 md:border-0"
            >
                { projects.map((project, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className="relative block md:flex items-stretch"
                                style={{minHeight: '19rem'}}>
                                <div
                                    className="relative w-full md:w-2/5 overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg"
                                    style={{minHeight: '19rem'}}>
                                    <img className="absolute inset-0 w-full h-full object-cover object-center"
                                         src={project.image}
                                         alt={project.imageAlt}/>
                                    <div className={`absolute inset-0 w-full h-full bg-${index % 2 ? 'primary' : 'secondary'} opacity-${index % 2 ? '75' : '85'}`}></div>
                                    <div
                                        className="absolute inset-0 w-full h-full flex items-center justify-center">
                                        <div>
                                            <img src={project.logo}
                                                 alt={project.title}
                                                 className="fill-current text-white w-3/4 xl:w-2/3 mx-auto"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-3/5 flex items-center">
                                    <div className="p-6 md:pr-12 md:pl-16 md:py-12 lg:pr-24 lg:pb-16">
                                        <p className="text-gray-700 prose">{project.summary}</p>
                                    </div>
                                    <span className="hidden lg:block absolute z-20 bottom-0 pb-3 right-0 pr-6 text-6xl font-bold text-gray-200">{`0${index + 1}`}</span>
                                    <svg className="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-gray-100 -ml-12"
                                        viewBox="0 0 100 100" preserveAspectRatio="none">
                                        <path id={`slide-${index}_path`} d="M50,0L100,0L50,100L0,100C24.377,72.416 46.218,42.943 50,0Z" />
                                    </svg>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                }) }
            </Swiper>

            <button className="absolute top-0 mt-32 left-0 -ml-6 bg-white rounded-full shadow-md outline-none h-12 w-12 text-2xl z-10 carousel-prev">
                <span className="block arrow-icon" style={{transform: 'scale(-1)'}}>&#x279c;</span>
            </button>
            <button className="absolute top-0 mt-32 right-0 -mr-6 bg-white rounded-full shadow-md outline-none h-12 w-12 text-2xl z-10 carousel-next">
                <span className="block arrow-icon" style={{transform: 'scale(1)'}}>&#x279c;</span>
            </button>

            <Swiper
                onSwiper={setThumbsSwiper}
                slidesPerView={projects.length}
                watchSlidesVisibility
                watchSlidesProgress
                className="hidden lg:block mb-8 pt-5 thumbnails-carousel"
            >
                { projects.map((project, index) => {
                    return (
                        <SwiperSlide className="flex items-center justify-center" key={index}>
                            <button className="px-2 client-logo">
                                <img className="w-full"
                                        src={project.logo}
                                        alt={project.title}/>
                            </button>
                        </SwiperSlide>
                    )
                }) }
            </Swiper>
        </div>
    );
};
