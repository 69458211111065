import React from "react"

import Seo from "../components/seo"
import {graphql} from "gatsby";
import ExcerptGrid from "../components/excerpt-grid";
import ClientsCarousel from "../components/clients-carousel";
import { getSrc } from "gatsby-plugin-image";
//import ClientLogos from "../components/client-logos";

export default function IndexPage({data}) {

    const services = data.services.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        rObj.image = getSrc(obj.node.frontmatter.image);
        return rObj
    });
    
    return(
        <>
            <Seo
                title="AWS Cloud Professionals"
            />

            <div className={"mb-10 px-6 text-center lg:mb-16 lg:px-12 xl:px-20 xl:w-3/4 mx-auto"}>
                <h1 className="text-4xl tracking-tighter leading-10 font-bold text-gray-800 sm:text-5xl sm:leading-none md:text-6xl prose prose-xl mx-auto">
                    Meet your trusted partner for all things <br /><span className="text-indigo-600">AWS cloud</span>
                </h1>
                <p className="mt-3 text-gray-700 sm:mt-5 prose prose-xl mx-auto">
                    Providing the expertise you need to get the best from AWS cloud services.<br className={"hidden md:block"}/> We're ready to help at any stage of your journey to the cloud.
                </p>
            </div>

            <div className="mx-auto px-6 md:px-12" style={{maxWidth: '1700px'}}>
                <div className="text-center">
                    <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Services</p>
                    <h2 className={"mt-2 text-3xl leading-8 font-bold font-display tracking-tight text-gray-800 sm:text-4xl sm:leading-10"}>How we can help</h2>
                    <p className="mt-4 mb-4 max-w-2xl prose prose-lg text-gray-700 lg:mx-auto">
                        We're able to draw on our vast experience with the AWS platform to deliver even the most challenging of projects.
                    </p>
                    <ExcerptGrid excerpts={services}/>
                </div>
            </div>

            <div className="py-12">

                <div className="max-w-screen-xl mx-auto px-6 md:px-12">
                    <div className="text-center">
                        <p className="text-base leading-6 text-indigo-600 font-semibold tracking-wide uppercase">Experience</p>
                        <h2 className="mt-2 text-3xl leading-8 font-bold font-display tracking-tight text-gray-800 sm:text-4xl sm:leading-10">
                            The clients we serve
                        </h2>
                        <p className="mt-4 mb-12 max-w-2xl prose prose-lg text-gray-700 lg:mx-auto">
                            Here's a selection of some of our recent client projects:
                        </p>
                    </div>

                    <ClientsCarousel/>
                </div>
            </div>

        </>
    )
}

export const query = graphql`{
  services: allMarkdownRemark(
    filter: {fileAbsolutePath: {glob: "**/content/services/*.md"}}
    sort: {fields: fileAbsolutePath, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          path
          title
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                quality: 75
                transformOptions: {duotone: {highlight: "#010CA0", shadow: "#010CA0", opacity: 65}}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`;
