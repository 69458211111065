import React from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

if (typeof window !== `undefined`) {
    gsap.registerPlugin(MorphSVGPlugin);
    MorphSVGPlugin.defaultType = 'rotational';
}

class ExcerptGrid extends React.Component {

    constructor(props){
        super(props);

        this.excerpts = props.excerpts;
        this.excerptClasses = props.excerptClasses;
        this.classNames = 'relative block text-center no-underline w-full image-excerpt';
        if (this.excerptClasses) this.classNames = this.classNames + ' ' + this.excerptClasses;

        this.blobClipPathPairs = [
            {
                from: 'M262.801,72.608C312.307,71.453 350.948,91.891 384.393,128.612C420.672,168.636 435.599,187.664 438.055,241.812C440.228,290.47 403.666,298.232 372.489,335.625C328.84,387.785 296.152,423.663 228.317,427.261C175.977,430.101 135.731,398.483 98.819,361.185C46.611,308.362 48.546,247.592 75.284,200.538C102.614,152.442 215.185,73.716 262.801,72.608Z',
                to: 'M288.59,409.09C234.953,420.408 172.881,493.479 122.583,450.814C72.38,408.124 27.754,310.48 38.875,258.654C49.909,207.272 84.457,186.31 117.97,146.192C157.056,99.477 147.94,62.895 205.204,41.271C257.448,21.373 283.758,60.202 334.836,107.809C385.862,155.219 425.085,206.98 443.346,252.83C459.419,293.188 477.204,343.182 444.4,382.907C416.255,416.797 340.109,398.219 288.59,409.09Z',
            },
            {
                from: 'M189,80.37 C 243,66.12 307.3,87.28 350.9,124.1 389.3,156.6 417,211.2 418.1,263.4 419.1,305.7 401.8,355.6 368.5,379.1 298.8,428 179.2,446.4 117.6,386.3 65.4,335.3 78.55,230.3 105.5,160.5 119.7,123.6 152.6,89.85 189,80.37Z',
                to: 'M88.108,82.159C144.8,27.551 333.957,0.001 348.286,62.526C360.645,116.573 333.635,124.455 358.259,211.036C374.609,268.309 453.93,261.029 450.159,331.791C444.948,428.092 123.584,523.263 69,443.524C17.572,368.286 84.9,325.791 88.063,264.276C91.284,202.977 3.307,163.75 88.108,82.159Z',
            },
            {
                from: 'M62.116,266.139C51.809,219.731 85.066,166.139 120.961,137.439C176.778,92.839 271.442,56.539 338.009,79.239C386.755,95.839 420.415,163.439 435.218,215.439C444.27,247.439 440.668,291.589 417.21,313.939C386.771,342.939 320.314,416.702 212.984,427.439C169.027,431.836 88.768,386.139 62.116,266.139Z',
                to: 'M30.03,240.4 C 31.35,186.2 39.67,120 85.95,86.14 162.5,30.12 302,3.296 378.7,59.08 413.1,84.14 383.6,140 395.1,178.8 411.6,234.4 477.8,280.7 469.5,337.7 462.6,384.1 420.9,429 373.8,447.7 286.1,482.5 161.8,482.4 87.79,427 33.04,386 28.46,305 30.03,240.4Z',
            },
            {
                from: 'M378.1,121.2 C 408.4,150 417.2,197.9 411,245.8 404.8,293.7 383.5,341.7 353.4,370.7 303.2,419.1 198.7,427.7 144.5,383.8 86.18,336.5 67.13,221.3 111.9,161 138.6,125 188.9,99.62 240.7,90.92 292.4,82.24 345.6,90.32 378.1,121.2Z',
                to: 'M420.96,153.923C463.088,216.587 498.621,314.506 454.721,375.979C420.074,424.64 374.796,398.025 276.465,412.624C144.471,432.188 98.456,492.369 50.924,434.968C0.004,373.595 94.4,282.33 125.278,209.238C151.46,147.567 152.838,49.062 218.786,31.226C295.167,10.481 377.454,89.173 420.96,153.923Z',
            }
        ]

        this.onHoverOver = (clipPathId, morphToClipPathId, imageId, bgCircleId) => {
            //console.log('mouse over');

            let morphTimeline = gsap.timeline();
            morphTimeline
                .to(clipPathId, 1, {morphSVG: morphToClipPathId, ease: 'elastic.out(1, 0.5)'}, 0)
                .to(imageId, 1, {scale: 1.1, transformOrigin: 'center center', ease: 'elastic.out(1, 0.5)'}, 0)
                .to(bgCircleId, 2, {scale: 0.90, transformOrigin: 'center center', ease: 'circ.out'}, 0);

        };

        this.onHoverOut = (clipPathId, morphToClipPathId, imageId, bgCircleId) => {
            //console.log('mouse out');

            let morphTimeline = gsap.timeline();
            morphTimeline
                .to(clipPathId, 1, {morphSVG: morphToClipPathId, ease: 'elastic.out(1, 0.5)'}, 0)
                .to(imageId, 1, {scale: 1, transformOrigin: 'center center', ease: 'elastic.out(1, 0.5)'}, 0)
                .to(bgCircleId, 2, {scale: 1, transformOrigin: 'center center', ease: 'circ.out'}, 0);

        };
    }

    //componentDidMount(){}
    //componentDidUpdate(prevProps, prevState){}

    render(){

        return (
            <div className="grid md:grid-cols-2 gap-6 md:gap-6 lg:gap-24 xl:gap-0 xl:grid-cols-4 justify-items-center excerpt-grid">
                { this.excerpts.map(( excerpt, index) => {

                    return (
                        <article className={"w-full max-w-sm"} key={index}>
                            <Link to={excerpt.path}
                                  className={this.classNames}
                                  onMouseOver={
                                      () => this.onHoverOver(
                                        `#item-${index}_path-1`,
                                        `#item-${index}_path-2`,
                                        `#item-${index}_image`,
                                          `#item-${index}_bg-circle`
                                      )
                                  }
                                  onFocus={
                                      () => this.onHoverOver(
                                          `#item-${index}_path-1`,
                                          `#item-${index}_path-2`,
                                          `#item-${index}_image`,
                                          `#item-${index}_bg-circle`
                                      )
                                  }
                                  onMouseOut={
                                      () => this.onHoverOut(
                                          `#item-${index}_path-1`,
                                          `#item-${index}_path-1`,
                                          `#item-${index}_image`,
                                          `#item-${index}_bg-circle`
                                      )
                                  }
                                  onBlur={
                                      () => this.onHoverOut(
                                          `#item-${index}_path-1`,
                                          `#item-${index}_path-1`,
                                          `#item-${index}_image`,
                                          `#item-${index}_bg-circle`
                                      )
                                  }
                            >

                                <svg viewBox="0 0 500 500" className={"relative z-10 pointer-events-none"}>
                                    <defs>
                                        <clipPath id={`item-${index}_clip-path-1`}>
                                            <path id={`item-${index}_path-1`} d={this.blobClipPathPairs[index].from}/>
                                        </clipPath>

                                        <clipPath id={`item-${index}_clip-path-2`}>
                                            <path id={`item-${index}_path-2`} d={this.blobClipPathPairs[index].to}/>
                                        </clipPath>
                                    </defs>
                                    <g id={`item-${index}_clip-path-0`} clipPath={`url(#item-${index}_clip-path-1)`}>
                                        <image
                                            id={`item-${index}_image`}
                                            className={"excerpt-grid-image"}
                                            xlinkHref={excerpt.image}
                                            x="0" y="0"
                                        />
                                    </g>
                                </svg>

                                <span className="excerpt-grid-number pointer-events-none absolute z-20 text-6xl opacity-75 font-bold text-secondary">{`0${index + 1}`}</span>
                                <h2 className="excerpt-grid-title pointer-events-none absolute z-20 text-3xl font-bold no-underline w-full text-white">{excerpt.title}</h2>
                                <div id={`item-${index}_bg-circle`} className={"excerpt-grid-circle pointer-events-none absolute rounded-full bg-gray-200"}></div>

                            </Link>
                        </article>
                    )
                }) }
            </div>
        )

    }
}

export default ExcerptGrid
